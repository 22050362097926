// LEGACY
import cx from 'classnames';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, memo, useRef } from 'react';

import BCItem from './breadcrumb-item';
import classes from './breadcrumbs.module.css';

const Breadcrumbs: FunctionComponent<BreadCrumbsProps> = memo(
  ({ items = [], currentPage }) => {
    const navRef = useRef();
    const currentPageRef = useRef();
    const { t } = useTranslation();
    const { locale = 'en', route } = useRouter();

    const isPrivateArea = route === '/private-area';

    return (
      <div
        className={cx(
          [classes.breadcrumbs],
          'flex',
          'flex-col',
          isPrivateArea && classes.isPrivateArea
        )}
        id="breadcrumbs"
      >
        <div className={cx('bg-neutral-10')}>
          <nav
            ref={navRef}
            className={cx(
              'flex',
              'container',
              'lg:mx-auto',
              'px-4',
              'inline-block',
              'overflow-y-auto'
            )}
          >
            <ol data-cy="breadcrums" className={cx('inline-block', 'shrink-0')}>
              <li className={cx('inline-block', 'mr-3')}>
                <BCItem href={`/${locale}`} text={t('common:home')} />
              </li>
              {items
                .filter(
                  ({ text, as, href }) =>
                    !!text &&
                    (typeof href === 'string' || !!as || !!href.pathname)
                )
                .map(({ text, ...linkProps }) => {
                  return (
                    <li key={text} className={cx('inline-block', 'mr-3')}>
                      <BCItem {...linkProps} text={text} />
                    </li>
                  );
                })}
            </ol>
            <span
              ref={currentPageRef}
              key="last"
              className={cx(
                'shrink-0',
                'whitespace-no-wrap',
                'pt-4',
                'pb-4',
                'text-sm',
                'font-bold'
              )}
            >
              {currentPage}
            </span>
          </nav>
        </div>
      </div>
    );
  }
);

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
